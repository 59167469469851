<template>
  <van-nav-bar :title=data.ghlxmc @click-left="onClickLeft" @click-right="onClickRight">
    <template #right>
      切换就诊人
      <van-icon name="exchange" size="18" />
    </template>
    <template #left>
      <van-icon name="arrow-left" /> 返回
    </template>
  </van-nav-bar>

  <van-cell-group>
    <van-cell size="large" icon="user-o" :title="data.defaultCard.xm" :label="data.defaultCard.kh" is-link
      :to="'/ShowCard?id=' + data.defaultCard.id" v-if="data.defaultCard"></van-cell>
    <van-cell size="large" icon="idcard" is-link to="/Cards" v-else>
      <template #title>
        <span style="color: #ed6a0c;">请先绑定电子就诊卡</span>
      </template>
    </van-cell>
  </van-cell-group>


  <div class="order">
    <div class="order-toper">
      <div class="order-title">挂号信息</div>
      <div class="order-info">

        <div class="order-li">就诊科室：{{ data.ksmc }}</div>
        <div class="order-li">看诊医生：{{ data.ysxm }}</div>
        <div class="order-li">医生排班：{{ data.bbmc }}</div>
        <div class="order-li">挂号类别：{{ data.lbmc }}</div>
        <div class="order-li">号源编号：{{ data.CurNum }}</div>
        <div class="order-li">看病时间：{{ data.seedate }}</div>
        <div class="order-li">挂号费用：<span style="color:#F56C6C">¥{{ data.ghfy }}</span></div>
        <!-- <div class="order-li">医保减免：<span style="color:#F56C6C">¥{{ data.yhje }}</span></div> -->
        <!-- <div class="order-li">个人支付：<span style="color:#F56C6C">¥{{data.ghfy- data.yhje }}</span></div> -->
      </div>
    </div>

    <div class="order-footer">
      <van-button class="order-button" :disabled="data.disabled" type="primary" @click.native="lockSerial('wxpay')">
        {{ data.ghlxmc }}
      </van-button>
    </div>

    <div class="order-footer">
      <van-button class="order-button" :disabled="data.ybzfDisabled" type="primary" @click.native="lockSerial('ybpay')">
        {{ data.ghlxmc_yb }}
      </van-button>
    </div>

    <div class="order-footer">
      <van-button class="order-button" :disabled="data.bankDisabled" type="primary" @click.native="lockSerial('bankpay')">
        {{ data.ghlxmc_bank }}
      </van-button>
    </div>


    <div class="order-footer" v-if="data.Cardno == ''"><van-button class="order-button" type="danger"
        @click.native="bindCard">绑定就诊卡</van-button></div>

    <div class="order-tips">
      医保支付，仅限本人使用，<br />
      <br />
      <br />
    </div>

    <div class="order-tips">
      支付后需要等待微信反馈消息，若消息提示成功方可认为挂号成功，若失败，费用会在7个工作日原路返回，若没有消息返回需等待确认。<br />
      <br />
    </div>
  </div>


  <van-popup v-model:show="data.show" round closeable position="bottom">
    <div class="footer">
      <van-cell-group>
        <van-cell size="large" icon="user-o" :title="card.xm" :label="card.kh" value="选择" is-link
          v-for="(card, k) in data.cardlist" :key="k" @click="choosecard(k)"></van-cell>
      </van-cell-group>
    </div>
  </van-popup>
</template>

<script setup>
import { postJson } from '@/api/AxiosINstance'
import { showToast, Button, showNotify, showFailToast } from 'vant';
import { onMounted, ref } from 'vue'
import router from '@/router'
import { useopenidStore } from '@/stores'

const openidstroe = useopenidStore();
const onClickLeft = () => history.back();
let data = ref({
  openid: '',
  show: false,
  defaultCard: {},
  channel: 'wxpay', //
  ghlx: 0,
  ghlxmc: "",
  ghlxmc_yb: "",
  ghlxmc_bank: "",
  disabled: false,
  ybzfDisabled: true,
  bankDisabled: true,
  ksbm: '',
  ksmc: '',
  ysbh: '',
  ysxm: '',
  bb: '',
  bbmc: '',
  ghlb: '',
  lbmc: '',
  ghfy: 0,
  yhje: 0,
  cardlist: [],
  numSourceId: 0,
  CurNum: "",
  ghrq: "",
  seedate: "",
  createorder_params: {},
})



onMounted(() => {
  console.log('订单入参')
  console.log(router.currentRoute.value.query)
  data.value.openid = useopenidStore().data.openid;

//医保支付
  if (data.value.openid == 'ooJ811XpfVpm-euqq8t7T_zRv_vM' || data.value.openid == 'ooJ811bjNwcb-e9LdISPGuQTJ6so') {
    data.value.ybzfDisabled = false
  }
  // 放开医保支付
   data.value.ybzfDisabled = false
   

   //银行聚合支付
   if (data.value.openid == 'ooJ811XpfVpm-euqq8t7T_zRv_vM' || data.value.openid == 'ooJ811bjNwcb-e9LdISPGuQTJ6so') {
    data.value.bankDisabled = false
  }



  if (data.value.openid) {
    data.value.defaultCard = JSON.parse(router.currentRoute.value.query.defaultCard)

    data.value.ghlx = router.currentRoute.value.query.ghlx
    if (data.value.ghlx == 1) {
      data.value.ghlxmc = '微信支付（当日挂号）'
      data.value.ghlxmc_yb = '医保支付（当日挂号）'
      data.value.ghlxmc_bank = '聚合支付（当日挂号）-测试中'
    }
    else if (data.value.ghlx == 2) {
      data.value.ghlxmc = '微信支付（预约挂号）'
      data.value.ghlxmc_yb = '医保支付（预约挂号）'
      data.value.ghlxmc_bank = '聚合支付（预约挂号）-测试中'
    }

    data.value.ksbm = router.currentRoute.value.query.ksbm
    data.value.ksmc = router.currentRoute.value.query.ksmc

    data.value.ysbh = router.currentRoute.value.query.ysbh
    data.value.ysxm = router.currentRoute.value.query.ysxm

    data.value.bb = router.currentRoute.value.query.bb
    data.value.bbmc = router.currentRoute.value.query.bbmc

    data.value.ghlb = router.currentRoute.value.query.ghlb
    data.value.lbmc = router.currentRoute.value.query.lbmc
    data.value.ghfy = router.currentRoute.value.query.ghf
    data.value.yhje = router.currentRoute.value.query.yhje
    data.value.numSourceId = router.currentRoute.value.query.numSourceId
    data.value.CurNum = router.currentRoute.value.query.CurNum
    data.value.ghrq = router.currentRoute.value.query.ghrq
    data.value.seedate = router.currentRoute.value.query.seedate

    console.log("numSourceId:" + data.value.numSourceId)
    getcardlist(data.value.openid)
  } else {
    showToast('系统异常');
  }

}

)

function getcardlist(openid) {
  let params = {
    lx: 'getcardbyopenid',
    openid: openid
  }
  console.log(params)

  postJson('WxpayService', params, 2).then(res => {
    if (res != null) {
      if (res.data.length) {
        data.value.cardlist = res.data
      } else {
        showToast('请先绑定就诊卡');
      }
    }
  })
}

function onClickRight() {
  data.value.show = true
}

function unlockSerial() {
  console.log("进入解锁")
  let params = {
    lx: 'unlockSerial',
    serialNumber: data.value.numSourceId,
    kh: data.value.defaultCard.kh
  }
  console.log("解锁入参")
  console.log(params)
  postJson('Wechat/Service', params, 2).then(res => {
    if (res.data.ret === 0) {
      showToast('解锁成功')
    } else {
      showToast(res.data.reinfo)
      console.log('解锁失败')
      console.log(res.data.reinfo)

    }
  }).catch(err => {
    showNotify({
      type: 'danger',
      message: '解锁失败'
    })
    console.log('错误返回')
    console.log(err)
    showFailToast(err)
  })

}

function lockSerial(channel) {
  data.value.channel = channel;
  console.log("进入锁号")
  if (data.value.defaultCard.kh === '') {
    showNotify({
      type: "danger",
      message: "没有卡号不能下单，请先绑定卡片！"
    })
  }

  let params = {
    lx: 'lockSerial',
    serialNumber: data.value.numSourceId,
    kh: data.value.defaultCard.kh
  }
  console.log("锁号入参")
  console.log(params)
  postJson('Wechat/Service', params, 2).then(res => {
    if (res.data.ret === 0) {
      showToast('锁号成功，正在创建订单')
      createOrder()
    } else {
      showNotify({
        type: 'danger',
        message: res.data.reinfo
      })
      console.log(res)

    }
  }).catch(err => {
    console.log('错误返回')
    console.log(err)
    showFailToast(err)
  })

}

//微信支付拉起收银台
function wx_lqsyt(result) {
  console.log("拉起微信收银台1");
  const temp = {
    appId: result.data.appId,
    timeStamp: result.data.timeStamp,
    nonceStr: result.data.nonceStr,
    package: result.data.packageValue,
    signType: result.data.signType,
    paySign: result.data.paySign,
  }
  WeixinJSBridge.invoke(
    'getBrandWCPayRequest', temp,
    function (res) {
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        router.replace({ path: '/todayResult', query: { orderid: result.data.orderid, ghlx: data.value.ghlx, channel: data.value.channel} });
        // wx.closeWindow();
      }
      else {
        unlockSerial()
        showNotify({
          type: "danger",
          message: "未支付，或者支付失败"
        })
      }
      // 使用以上方式判断前端返回,微信团队郑重提示：
      // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。     
    }
  );

}

function ybpay(result) {
if (result.data.data.code === 1) {
  window.location.href = result.data.data.data.payUrl; 
}
else{
  unlockSerial()
  showNotify({
        type: 'danger',
        message: result.data.data.message
      })
}
 
}

function bankpay(result) {
if (result.data.respCode === '0000000000') {
  window.location.href = result.data.cashierPayHtml; 
}
else{
  unlockSerial()
  showNotify({
        type: 'danger',
        message: result.data.respMsg
      })
}
 
}

function createOrder() {
  // Indicator.open('加载中...');
  if (data.value.ghlx == 1) {
    createOrder_drgh_input()
  }
  if (data.value.ghlx == 2) {
    createOrder_yygh_intput()
  }
  console.log("下单入参")
  console.log(data.value.createorder_params)
  if (data.value.createorder_params.card_no === '') {
    showNotify({
      type: "danger",
      message: "没有卡号不能下单，请先绑定卡片！"
    })
  }
  //第一步统一下单
  postJson('WxpayService', data.value.createorder_params, 2).then(result => {

    console.log(JSON.stringify(result.data));

    if (result.data != null) {
      if (data.value.channel == 'wxpay') {
        wx_lqsyt(result)
      }
      if (data.value.channel == 'ybpay') {
        ybpay(result)
      }
      if (data.value.channel == 'bankpay') {
        bankpay(result)
      }
    }
    else {
      unlockSerial()
      showNotify({
        type: 'danger',
        message: '下单失败'
      })

    }
  }).catch(err => {
    // Indicator.close();
    console.log(err);
    showFailToast('下单异常！')
    unlockSerial()

  });
}


function createOrder_drgh_input() {
  data.value.createorder_params = {
    lx: 'createorder',
    openid: data.value.openid,
    card_no: data.value.defaultCard.kh,
    TradeType: 'JSAPI',
    order_type: 'drgh',
    zje: data.value.ghfy,
    discount: 0,
    yhhje: data.value.ghfy - data.value.yhje,
    ysbh: data.value.ysbh,
    ysxm: data.value.ysxm,
    name: data.value.Name,
    bb: data.value.bb,
    bbmc: data.value.bbmc,
    zfzl: 3,
    ksbm: data.value.ksbm,
    ksmc: data.value.ksmc,
    ghlb: data.value.ghlb,
    ghlbmc: data.value.lbmc,
    numSourceId: data.value.numSourceId,
    channel: data.value.channel
  }
  console.log('当日挂号入参')
  console.log(data.value.createorder_params)
}

function createOrder_yygh_intput() {

  data.value.createorder_params = {
    lx: 'createorder',
    openid: data.value.openid,
    card_no: data.value.defaultCard.kh,
    TradeType: 'JSAPI',
    order_type: 'yygh',
    zje: data.value.ghfy,
    discount: 0,
    yhhje: data.value.ghfy - data.value.yhje,
    ysbh: data.value.ysbh,
    rq: data.value.seedate,
    ysh_lx: data.value.bb - 1,
    numSourceId: data.value.numSourceId,
    kh: data.value.defaultCard.kh,
    ksbm: data.value.ksbm,
    ghlb: data.value.ghlb,
    zfje: data.value.ghfy - data.value.yhje,
    yhje: data.value.yhje,
    channel: data.value.channel
  }
  console.log('预约挂号入参')
  console.log(data.value.createorder_params)
}



function setDefault(id) {

  let params = {
    lx: 'setdefaultcard',
    id: id,
    openid: data.value.openid,
  }

  postJson('WxpayService', params, 2).then(res => {
    if (res.data.ret == 0) {
      getcardlist()
    }
    else {
      showFailToast(res.data.reinfo);
    }
  }).catch(err => {
    console.log('错误返回')
    console.log(err)
    showFailToast(err)
  })

}

function choosecard(k) {
  data.value.defaultCard = data.value.cardlist[k]
  data.value.show = false
  setDefault(data.value.defaultCard.id)
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.van-nav-bar {
  background: #1989fa;

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon {
    color: white;
  }

  /deep/ .van-nav-bar__right {
    color: white;
  }

  /deep/ .van-nav-bar__left {
    color: white;
  }
}

.order {
  width: 100%;
}

.order-toper {
  background: #ffffff;
}

.order-title {
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid #E4E7ED;
  padding: 0.5rem 0;
}

.order-info {
  width: 90%;
  margin: 0 auto;
  padding: 0.5rem 0;
  font-size: 1rem;
}

.order-li {
  line-height: 2rem;
}

.order-mainer {
  background: #ffffff;
  padding: 1rem 0;
  margin-top: 1rem;
}

.order-select {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #E4E7ED;
}

.order-cards {
  width: 90%;
  margin: 0 auto;
}

.order-cardno {
  border: 1px solid #E4E7ED;
  border-radius: 4px;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  display: flex;
}

.card-info {
  flex: 1
}

.card-no {
  color: #909399;
}

.card-radio {
  padding-top: 0.5rem;
}

.order-footer {
  width: 90%;
  margin: 0 auto;
  margin-top: 1rem;
}

.order-button {
  width: 100%;
}

.order-tips {
  width: 90%;
  margin: 0 auto;
  margin-top: 1rem;
  font-size: 0.75rem;
  color: #E6A23C;
  padding-bottom: 2rem
}
</style>